@font-face {
  font-family: Pixeboy;
  src: url(./Pixeboy-z8XGD.ttf);
}
body {
  background-image: url(./dino.gif);
  background-size: cover;
  height: 100vh;
  width: 100%;
}
p {
  font-size: 200px;
  color: rgb(255, 255, 255);

  font-family: Pixeboy;
  /* text-shadow: 5px 5px #fff; */
}
.App {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 1200px) {
  p {
    font-size: 100px;
    color: rgb(255, 255, 255);

    font-family: Pixeboy;
    /* text-shadow: 5px 5px #fff; */
  }
  .App {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
